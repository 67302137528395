<style scoped lang="less">
  .salary-cop-config-page {
    margin: 10px;
    padding: 10px;
    background-color: #FFF;
    border-radius: 5px;
  }
</style>

<template>
  <div class="salary-cop-config-page">
    <Form label-colon>
      <FormItem :label-width="80" label="类型">
        <RadioGroup @on-change="loadData" button-style="solid" v-model="type" type="button">
          <Radio :label="1">正式</Radio>
          <Radio :label="2">辅助</Radio>
          <Radio :label="3">退役</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem :label-width="80" label="月份">
        <DatePicker @on-change="loadData" v-model="month" type="month" placeholder="请选择月份"></DatePicker>
      </FormItem>
    </Form>
    <Form :label-width="80" label-colon :rules="rules" v-if="formData" :model="formData" :key="formKey">
      <FormItem v-for="(item, index) in dataList" :props="item.key" :key="item.key" :label="'项目' + (index + 1)">
        <Input v-model="formData[item.key]" placeholder="请输入名称" />
      </FormItem>
      <FormItem>
        <Button :loading="loading" @click="submit" type="primary">保存 </Button>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import { getColumnConfigs } from './lib'
import { request } from '@/api'
export default {
  data () {
    return {
      type: 1,
      month: new Date(),
      dataList: [],
      rules: {},
      formData: null,
      loading: false,
      formKey: 1
    }
  },
  methods: {
    async submit () {
      this.loading = true
      try {
        await request('/ucenter/sys_config', 'post', {
          type: 'cop_columns_' + this.type,
          configKey: this.$datetime.format(this.month, 'YM'),
          name: this.$datetime.format(this.month, 'Y年M月'),
          value: JSON.stringify(this.dataList.map(item => {
            return {
              title: this.formData[item.key],
              key: item.key
            }
          }))
        })
        this.$Message.success('已保存')
        await getColumnConfigs(this.type, this.$datetime.format(this.month, 'YM'), true)
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading = false
    },
    async loadData () {
      const configs = await getColumnConfigs(this.type, this.$datetime.format(this.month, 'YM'))
      this.rules = configs.rules
      this.formData = configs.formData
      this.dataList = configs.dataList
      this.formKey++
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
